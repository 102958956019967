.producto {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  width: 15rem;
  margin-bottom: 10px;
}

.imgContainer {
  overflow: hidden;
}

.producto div {
  margin: 0.5em;
  background-color: white;
  display: flex;
  flex-direction: column;
  
}

.producto img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.producto .ver-mas {
  text-decoration: none;
  background-color: #8ab9c4;
  color: white;
  padding: 0.5rem;
  width: max-content;
  border-radius: 0.5rem;
  transition: 0.2s;
  margin-top: 1rem;
  font-size: 0.8rem;
}

.productoInicio h4 {
  font-size: 1.2rem;
  font-weight: bold;
}

.productoInicio p {
  font-size: 0.8rem;
}
