.margenes {
  margin-left: 5rem;
  margin-right: 5rem;
}

/* .preciosContainer {
  height: 15rem;
} */

.descripcionPreciosContainer {
  min-height: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.listaPreciosContainer {
  display: flex;
  align-items: center;
  border-radius: 25px;
  width: fit-content;
  height: 30px;
  border: 2px solid #8ab9c4;
  overflow: hidden;
}

.listaPreciosTitulo {
  text-align: center;
  color: black;
  padding: 1rem;
  width: 8rem;
  height: 45px;
  font-size: 0.8rem;
  margin: 0;
}

.btnPrecios {
  height: 38px;
  background-color: #fff;
  color: black;
  border-radius: 0;
}

.btnPreciosSelected {
  background-color: #8ab9c4;
  color: white;
}

.btnPreciosSelected:hover {
  background-color: #8ab9c4;
}

.carritoContainer {
  width: 100%;
  border-collapse: collapse;
  margin-top: 2rem;
  margin-bottom: 20px;
}

.compraMinima {
  font-size: 0.8rem;
  color: black;
  padding: 5px;
  text-align: start;
}

.encabezadoCarrito {
  background-color: #8ab9c4;
  color: white;
  padding: 5px;
  text-align: center;
}

.encabezadoCarrito th {
  font-size: 0.8rem;
  padding: 5px;
}

.artContainer td {
  font-size: 0.8rem;
  padding: 5px;
  text-align: center;
}

.button-container {
  display: flex;
  gap: 1rem;
  justify-content: end;
  margin-top: 3rem;
  margin-bottom: 30rem;
}

.totalContainer {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-left: auto;
  border: 1px solid black;
  width: 14rem;
  padding: 0.3rem;
  background-color: #8ab9c4;
}

.cantidadTotal {
  padding-left: 1rem;
  color: white;
  font-size: 14px;
  
}

.precioTotal {
  padding-left: 1rem;
  color: white;
  font-size: 15px;
  font-weight: bold;
}

.linkForm {
  text-decoration: none;
  font-size: 13px;
  background-color: #8ab9c4;
  color: white;
  border-radius: 0.5rem;
  padding: 10px 15px;
}

.linkForm:hover {
  background-color: #8ab9c4;
}

button {
  background-color: #8ab9c4;
  color: white;
  padding: 5px 7px;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 13px;
}

button:hover {
  background-color: #8ab9c4;
}

.carritoVacioContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10rem;
}

.carritoVacio {
  background-color: #8ab9c4;
  color: white;
  width: 17rem;
  font-size: 1rem;
  text-align: center;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.delete-icon-img:hover {
  cursor: pointer;
}

.alertContainer {
  display: flex;
  flex-direction: column;
  width: 25rem;
  margin-left: auto;
  margin-top: 1rem;
  position: fixed;
  top: -100%; /* Comienza fuera de la pantalla */
  transition: top 0.3s ease; /* Agrega una transición suave */
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
}

.alertContainer.active {
  top: 0; /* Mueve el alert hacia abajo para que aparezca */
}

.alertVaciar {
  background-color: white;
  border: 1px solid #8ab9c4;
}

.alertVaciar div {
  margin-top: 1.5rem;
  display: flex;
  justify-content: flex-end;
}

.aceptarVaciar {
  margin-right: 0.5rem;
}

@media (max-width: 727px) {
  .margenes {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

@media (max-width: 450px) {
  .listaPreciosContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 25px;
    width: 140px;
    height: 11rem;
    border: 2px solid #8ab9c4;
    overflow: hidden;
  }

  .compraMinima {
    text-align: center;
  }

  .btnPrecios {
    width: 110px;
    border-radius: 0.5rem;
  }

  .preciosContainer {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    flex-direction: row-reverse;
  }
  
  .descripcionPreciosContainer {
    width: 140px;
  }
}