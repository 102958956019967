.contenedor-resumen-pedido {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.titulo-resumenPedido {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    background-color: #8ab9c4;
    color: white;
    width: auto;
    padding: 0.7rem;
    border-radius: 0.5rem;
    margin-top: 3rem;
}

.contenedor-grilla-individual {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.table-grilla-resumen {
    margin: 1.3rem;
    border-collapse: collapse;
}

.table-grilla-resumen th {
    border: 1px solid #000;
    font-weight: normal;
    text-align: center;
}

.table-grilla-resumen td {
    border: 1px solid #000;
    padding: 0.1rem 0.3rem 0.1rem 0.3rem;
    text-align: center;
    font-weight: normal;
}

.celda-resaltada {
    background-color: #8ab9c4;
    color: white;
    font-weight: bold !important;
}