.table-grilla {
    width: auto;
    height: 10px;
    margin-top: 2rem;
    margin-left: 1rem;
    border-collapse: collapse;
}

.table-grilla th,
.table-grilla td {
    border: 1px solid #000000;
    padding: 0.1rem 0.3rem 0.1rem 0.3rem;
    font-weight: normal;
    text-align: center;
}

#articulo-grilla-elegido {
    background-color: #8ab9c4;
    color: white;
    font-weight: bold !important;
}

.table-grilla-input {
    position: relative;
}

.table-grilla-input input {
    width: 40px;
    text-align: center;
}

.stock-label {
    font-weight: bold;
}

.confirmarCargarPedido {
    height: 100%;
    padding: 0.5rem;
    border-radius: 0.5rem;
    background-color: #8ab9c4;
    margin-bottom: 1rem;
    margin-right: 1rem;
    margin-top: auto;
    margin-left: 1rem;
}

.item-count-button {
    background-color: #8ab9c4;
    border: 0;
    width: 2rem;
    height: 2rem;
    color: white;
    border-radius: .5rem;
    cursor: pointer;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 1;
    border: 2px solid #8ab9c4;
    transition: .2s;
}

.item-count-cantidad {
    border-bottom: 1px solid #8ab9c4;
    padding-left: .1rem;
    padding-right: .1rem;
    font-size: 1rem;
    font-style: normal;
}

.item-count-button:hover {
    background-color: white;
    color: black;
}