.container {
  padding-inline: 1.5rem;
  margin: 0 auto;
}

.producto-detalle {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 3rem;
  margin-top: 7rem;
}

/* Estilo para el contenedor principal del carrusel y miniaturas */

.contenedor-carousel {
  width: 100%; /* Ajusta según tus preferencias */
  height: auto; /* Ajusta según tus preferencias */
  overflow: hidden; /* Evita que las imágenes más grandes se desborden */
}

.imgItemDetail {
  width: 100%;
  height: 25rem;
  object-fit: contain;
}

/* Estilo para el contenedor de miniaturas */
.miniaturas {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  height: 150px;
}

.infoContainer {
  width: 40rem;
}

.precio {
  font-size: 1.5rem;
  margin-top: 2rem;
  font-weight: 600;
  padding-top: 0.5rem;
}

.tallesItemDetail {
  margin-top: 1rem;
  border-top: 1px solid #ddd;
}

.colorItemDetail {
  margin-top: 1rem;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.checkTalle {
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
  display: flex;
  flex-wrap: wrap;
}

.talleLabel {
  display: block;
  text-transform: uppercase;
  font-size: 1rem;

  padding: 0.2rem;
}

.talleInput {
  margin-right: 5px;
}

.checkColor {
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
  display: flex;
  flex-wrap: wrap;
}

.colorLabel {
  display: block;
  text-transform: uppercase;
  font-size: 1rem;

  padding: 0.2rem;
}

.colorInput {
  margin-right: 5px;
}

input {
  accent-color: #8ab9c4;
}

.descripcion {
  width: 15rem;
  background-color: #8ab9c4;
  padding: 0.5rem;
  color: white;
  overflow-wrap: break-word;
}

.productosRelacionados {
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  margin-top: 5rem;
}

.productosRelacionados h3 {
  text-align: center;
  background-color: #8ab9c4;
  padding: 0.5rem;
  margin-bottom: 2rem;
  color: white;
}

.productosRelacionadosLista {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 2rem;
}

.imgContainer {
  width: 14rem;
  height: 14rem;
  overflow: hidden;
}

.productosRelacionadosLista img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.productosRelacionadosLista p {
  padding-bottom: 0.2rem;
}

.detalleContainer {
  margin-top: 1rem;
}

.detalle {
  text-decoration: none;
  background-color: #8ab9c4;
  color: white;
  padding: 0.5rem;
  width: max-content;
  border-radius: 0.5rem;
  transition: 0.2s;
}


.modalTitle {
  font-size: 0.8rem;
  background-color: #8ab9c4;
  color: black;
  padding: 0.3rem;
  border-radius: 0.5rem;
  
}

.modalHeader {
  border: none !important;
  justify-content: space-evenly;
  gap: 0.1rem;
  background-color: rgb(239, 239, 239);
  border-radius: 0.5rem;
}


.btn-custom {
  font-size: 0.7rem !important;
  background-color: #8ab9c4;
  color: black;
  border-radius: 0.5rem;
  cursor: pointer;
  border: 2px solid #8ab9c4;
  padding: 0.2rem;
}

.btn-custom:hover {
  background-color: white !important;
  color: black !important;
  border: 2px solid #fbccd2 !important;
}

@media (max-width: 769px) {
  .producto-detalle {
    display: flex;
    flex-direction: column;
  }
  .descripcion {
    width: 22rem;
  }
  .infoContainer {
    width: 20rem;
  }
  .imgItemDetail {
    width: 100%;
    height: 23rem;
    object-fit: contain;
  }
  .miniaturas {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    height: 6rem;
  }
}

@media (max-width: 815px) {
  .productosRelacionadosLista {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-left: 1rem;
  }
}

@media (max-width: 650px) {
  .productosRelacionadosLista {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-left: 1rem;
  }
}

@media (max-width: 480px) {
  .productosRelacionadosLista {
    display: flex;
    flex-direction: column;
    
  }

}