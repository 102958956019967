.volverContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: -150px;
    height: 70vh;
}

.volver {
    background-color: #8ab9c4;
    padding: 0.5rem;
    border-radius: 0.5rem;
    color: white;
    font-weight: bold;
    width: 10rem;
    text-align: center;
}