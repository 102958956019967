* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Roboto;
}


.body {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "header header"
    "sidebar main"
    "footer footer";
  min-height: 100vh;

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.header {
  grid-area: header;
  background-color: #8ab9c4;
  color: white;
  padding: 1rem;
}
.main {
  grid-area: main;
  padding: 1rem;
}

.sidebar {
  grid-area: sidebar;
  background-color: #eee;
  padding: 1rem;
}

.footer {
  grid-area: footer;
  background-color: #8ab9c4;
  color: white;
  padding: 1rem;
  text-align: center;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  margin: 0 !important;
}