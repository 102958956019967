   /* colores catalogo
   #c4b8aa
   #6b4e50 
   #8ab9c4  
   #a2aaa9 
   #5e3f41  
   #d1c2dc  
   #f9d0dc 
   #463d44 
   #41494d 
   
    #cabac6
    #bebfac
    #aa3f44
    #a67485
    #dea598
    #8ab9c4
   */


   .encabezado{
    font-size: 0.7rem;
    text-align: center;
    padding-left: 5rem;
    margin-bottom: 0;
}

.navbar {
    display: flex;
    align-items: center;
    background-color: #8ab9c4;
    
}

.logo {
    text-decoration: none;
    color: white;
    margin-left: 2rem;
    
}

.logo h1 {
    font-size: 3.5rem;
    margin-bottom: 0;
}

.menu {
    list-style-type: none;
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 0;
    padding: 1rem;
}

.menu-link {
    text-decoration: none;
    color: white;
    font-size: 1.5rem;
}

.menu-link:hover {
    text-decoration: underline;
}

.redesWhatsapp {
    position: fixed; 
    bottom: 2.8rem;
    right: 0.1px;
    z-index: 1000;
    
}
.redesWhatsapp img{
    width: 3rem;
    margin-right: 1rem;
}

@media (max-width: 769px) {
    .navbarMobileContainer {
        background-color: #8ab9c4;
        padding: .5rem 1rem;
    }

    .encabezadoMobile {
        font-size: 0.6rem;
        text-align: center;
        margin-bottom: 0;
        background-color: #ffffffb3;

    }

    .navbarMobile {
        align-items: center;
        background-color: #8ab9c4;
        display: flex;
        justify-content: space-between;
        margin-top: .5rem;
        
    }

    .logo {
        justify-content: center; 
        margin-left: 0; 
    }
    
    .logo h1 {
        font-size: 3.5rem;
    }
}
@media (max-width: 550px) {
    .redesWhatsapp img{
        width: 2rem;
        margin-right: 2rem;
    }
    .redesWhatsapp {
        position: fixed; 
        bottom: 2.5rem;
        right: 0.1px;
        z-index: 1000;            
    }
}

