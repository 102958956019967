.item-count {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
}

.numeroCantidad {
    border-bottom: 2px solid #969696; /* Ajusta el grosor y el estilo según tus preferencias */
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    
}

.item-count button, .agregar-al-carrito, .enviar {
    background-color: #8ab9c4;
    border: 0;
    width: 2rem;
    height: 2rem;
    color: white;
    border-radius: .5rem;
    cursor: pointer;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 1;
    border: 2px solid #8ab9c4;
    transition: .2s;
}

.item-count button:hover, .agregar-al-carrito:hover, .enviar:hover {
    background-color: white;
    color: black;
}

.agregar-al-carrito, .enviar {
    width: auto;
    height: auto;
    margin-top: 1rem;
    padding: 0.5rem;
}