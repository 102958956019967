.container {
    max-width: 1140px;
    padding-inline: 1.5rem;
    margin: 0 auto;
}

.enviamosPagaSeguridad{
    display: flex;
    justify-content: center; 
    text-align: center;
    background-color: rgba(112, 132, 139, 0.3);
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    position: relative;
    overflow: hidden;
    margin-top: 1rem;
    margin-bottom: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.enviamosPagaSeguridad img {
    width: 2rem;
    margin-right: 1rem;
    margin-left: 1rem;
    
}

.enviamosPagaSeguridad h3 {
    font-size: 1.2rem;
    font-weight: bold;
    color: #70848b;
}

.enviamosPagaSeguridad p {
    font-size: 0.8rem;
    color: #70848b;
} 
  
  .slide-container {
    display: flex;
    gap: 0;
    align-items: center;
    animation: slide-in 2s linear infinite;
  }

  .a {
    width: 20rem;
  }

  
  .imgCelSlide {
    display: none;
  }

  @keyframes slide-in {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(11.1%);
    }
  }


@media (max-width: 769px) {
    .a {
      display: flex;
      align-items: center;
    }

    .enviamosPagaSeguridad img {
        width: 1.6rem;
    }
    
    .enviamosPagaSeguridad h3 {
        display: none;
    }
    
    .enviamosPagaSeguridad p {
        font-size: 0.8rem;
        font-weight: bold;
        text-align: center;
    }

    .imgCelSlide {
      display: block;
    }

}