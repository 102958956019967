.imgContianer {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 0;    
    padding-inline: 1.5rem;
    margin: 0 auto;
    
}

.imgItem {
    text-align: center;
}

.imgGaleria {
    max-width: 75rem; 
    max-height: 60rem; 
    margin: 0 auto; 
    display: block; 
}


@media (max-width: 769px) {
    .imgContianer {
        padding-inline: 0.5rem; 
    }

    .imgGaleria {
        max-width: 100%; 
        max-height: auto; 
    }
}