.desplegable{
    background-color: #8ab9c4 !important;
    border: none !important;
    font-size: 1.5rem !important;
    font-family: sans-serif;
}

.desplegable:hover {
    text-decoration: underline;
    
}

.desplegable-menu{
    text-decoration: none;
    background-color: #8ab9c4;
    color: white;
    padding: 0.3rem;
    border-radius: 0.5rem;
    font-size: 0.8rem !important;

}

.fondoItem:active {
  background-color: inherit !important;
}


  

