.contenedor-inicial {
  display: flex;
  align-items: center;
  justify-content: center;
}

.explicacion-inicial {
  background-color: #8ab9c4;
  margin-top: 1rem;
  color: white;
  font-size: 10px;
  width: fit-content;
  padding: 0.3rem 0.5rem;
  border-radius: 0.5rem;
  text-align: center;
}

.mayoristaContainer {
    margin: 20px;
  }

  th, td {
    border: 1px solid #010101;
    padding: 8px;
    text-align: center;
    font-size: 13px;
  }
  
  /* Estilo específico para el encabezado de la tabla */
  thead th {
    font-weight: bold;
  }
  
  /* Estilo específico para el cuerpo de la tabla */
  tbody td {
    font-style: italic;
  }
  
  .contenedor-tabla-grilla {
    display: flex;
    max-height: 400px;
    overflow-y: auto;
    margin: 1rem;
    border: 1px solid #010101;
    margin-top: 5rem;
    margin-bottom: 3rem;
  }
  
  .table-cargarPedido-contenedor {
    border: 1px solid #010101;
  }

  .table-header-productos {
    border: 1px solid #010101;
    background-color: #8ab9c4;
    font-weight: bold;
  }

  .table-header-articulos {
    padding: 0.5rem;
    color: white;
  }

  .table-cell-productos {
    border: 1px solid #010101;
    padding: 0.5rem 1rem 0.5rem 1rem;
  }

  .table-cell-productos.selected {
    background-color: #d2dfe4;
  }

  .contenedor-tabla-grilla {
    display: flex;
    max-height: 400px;
    overflow-y: auto;
    margin: 1rem;
    border: 1px solid #000;
  }

  .scrollable-list-container {
    max-height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .table-cargarPedido-contenedor {
    overflow-y: auto;
    border: 1px solid #000000;
    border-collapse: collapse;
    margin: 2rem;
    padding: 2rem;
  }